/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import 'react-times/css/material/default.css';
import 'react-toastify/dist/ReactToastify.css';
import { store, persistor } from 'store';
import { BrowserRouter } from 'react-router-dom';
import { DEFAULT_QUERY_OPTION } from 'utils/constants';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RoutesLayout from 'routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import Location from 'components/common/Location';
import ScrollToTop from 'components/common/ScrollToTop';
import PopUpConfirmLogin from 'components/common/Modal/PopUpConfirmLogin';
import { ColorSystem } from 'components/common/ColorSystem';
import { setApiUrl, setAppVersion } from 'services/config';
import { useAppSelector } from 'store/hooks';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: DEFAULT_QUERY_OPTION,
    },
});
const App = () => <RoutesLayout />;

const EnvironmentWrapper: React.FC = () => {
    const apiUrl = useAppSelector((state) => state.config?.apiUrl);
    const getConfig = () => {
        fetch(`/config.json`, {
            headers: {
                'Cache-Control': 'no-cache',
            },
        })
            .then((e) => e.json())
            .then((res) => {
                store.dispatch(setApiUrl(res.apiUrl));
                store.dispatch(setAppVersion(res.appVersion));
            });
    };

    useEffect(() => {
        getConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderFunc = useMemo(() => {
        if (!apiUrl) {
            return null;
        }
        return (
            <>
                <ToastContainer />
                <App />
                <PopUpConfirmLogin />
                <Location />
                <ScrollToTop />
                <ColorSystem />
            </>
        );
    }, [apiUrl]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (
        <>
            {renderFunc}
        </>
    );
};

const AppWrapper: React.FC = () => (
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <EnvironmentWrapper />
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    </BrowserRouter>
);
export default AppWrapper;
