import { createSlice } from '@reduxjs/toolkit';

const initialState: { apiUrl: string, appVersion: string } = {
    apiUrl: '',
    appVersion: '',
};

const configSlice = createSlice({
    name: 'configSlice',
    initialState,
    reducers: {
        setApiUrl: (
            state,
            action,
        ) => {
            state.apiUrl = action.payload;
        },

        setAppVersion: (
            state,
            action,
        ) => {
            state.appVersion = action.payload;
        },
    },
});

export const { setApiUrl, setAppVersion } = configSlice.actions;
export const ConfigReducer = configSlice.reducer;
